import { ChainId, ERC20Token } from '@grokswap/sdk'

export const XAI_MAINNET = new ERC20Token(
  ChainId.BSC,
  '0x47a32A42248Ac1901792FD357dD560005E19D7Bb',
  18,
  'XAI',
  'GrokSwap Token',
  'https://grokswap.finance/',
)

export const X_TESTNET = new ERC20Token(
  ChainId.BSC_TESTNET,
  '0xFa60D973F7642B748046464e165A65B7323b0DEE',
  18,
  'XAI',
  'GrokSwap Token',
  'https://grokswap.finance/',
)

export const USDC_BSC = new ERC20Token(
  ChainId.BSC,
  '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  18,
  'USDC',
  'Binance-Peg USD Coin',
  'https://www.centre.io/usdc',
)

export const USDC_TESTNET = new ERC20Token(
  ChainId.BSC_TESTNET,
  '0x64544969ed7EBf5f083679233325356EbE738930',
  18,
  'USDC',
  'Binance-Peg USD Coin',
  'https://www.centre.io/usdc',
)

export const USDC_ETH = new ERC20Token(
  ChainId.ETHEREUM,
  '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
  6,
  'USDC',
  'USD Coin',
)

export const USDC_GOERLI = new ERC20Token(
  ChainId.GOERLI,
  '0x07865c6E87B9F70255377e024ace6630C1Eaa37F',
  6,
  'tUSDC',
  'test USD Coin',
)

export const USDT_BSC = new ERC20Token(
  ChainId.BSC,
  '0x55d398326f99059fF775485246999027B3197955',
  18,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)

export const USDT_ETH = new ERC20Token(
  ChainId.ETHEREUM,
  '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  6,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)

export const BUSD_BSC = new ERC20Token(
  ChainId.BSC,
  '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_TESTNET = new ERC20Token(
  ChainId.BSC_TESTNET,
  '0xaB1a4d4f1D656d2450692D237fdD6C7f9146e814',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_ETH = new ERC20Token(
  ChainId.ETHEREUM,
  '0x4Fabb145d64652a948d72533023f6E7A623C7C53',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_GOERLI = new ERC20Token(
  ChainId.GOERLI,
  '0xb809b9B2dc5e93CB863176Ea2D565425B03c0540',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD = {
  [ChainId.ETHEREUM]: BUSD_ETH,
  [ChainId.GOERLI]: BUSD_GOERLI,
  [ChainId.BSC]: BUSD_BSC,
  [ChainId.BSC_TESTNET]: BUSD_TESTNET,
}

export const XAI = {
  [ChainId.BSC]: XAI_MAINNET,
  [ChainId.OPBNB]: new ERC20Token(
    ChainId.OPBNB,
    '0x47a32A42248Ac1901792FD357dD560005E19D7Bb',
    18,
    'XAI',
    'GrokSwap Token',
    'https://grokswap.finance/',
  ),
  [ChainId.SCROLL]: new ERC20Token(
    ChainId.SCROLL,
    '0x5de65E61eac3F8e9Bc4bA8De4090554e6A3Bd815',
    18,
    'XAI',
    'GrokSwap Token',
    'https://grokswap.finance/',
  ),
  [ChainId.BSC_TESTNET]: X_TESTNET,
}

export const USDC = {
  [ChainId.BSC]: USDC_BSC,
  [ChainId.BSC_TESTNET]: USDC_TESTNET,
  [ChainId.ETHEREUM]: USDC_ETH,
  [ChainId.GOERLI]: USDC_GOERLI,
}

export const USDT = {
  [ChainId.BSC]: USDT_BSC,
  [ChainId.OPBNB]: new ERC20Token(
    ChainId.OPBNB,
    '0x9e5AAC1Ba1a2e6aEd6b32689DFcF62A509Ca96f3',
    18,
    'USDT',
    'Tether Token',
    'https://tether.to/',
  ),
  [ChainId.SCROLL]: new ERC20Token(
    ChainId.SCROLL,
    '0x9e5AAC1Ba1a2e6aEd6b32689DFcF62A509Ca96f3',
    18,
    'USDT',
    'Tether Token',
    'https://tether.to/',
  ),
  [ChainId.ETHEREUM]: USDT_ETH,
}

export const WBTC_ETH = new ERC20Token(
  ChainId.ETHEREUM,
  '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
  8,
  'WBTC',
  'Wrapped BTC',
)

export const WBTC = {
  [ChainId.BSC]: new ERC20Token(ChainId.BSC, '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c', 18, 'WBTC', 'Wrapped BTC'),
  [ChainId.OPBNB]: new ERC20Token(
    ChainId.OPBNB,
    '0x7c6b91D9Be155A6Db01f749217d76fF02A7227F2',
    18,
    'WBTC',
    'Wrapped BTC',
  ),
  [ChainId.ETHEREUM]: WBTC_ETH,
}

export const ETH = {
  [ChainId.BSC]: new ERC20Token(ChainId.BSC, '0x2170Ed0880ac9A755fd29B2688956BD959F933F8', 18, 'ETH', 'Ethereum'),
  [ChainId.OPBNB]: new ERC20Token(ChainId.OPBNB, '0xE7798f023fC62146e8Aa1b36Da45fb70855a77Ea', 18, 'ETH', 'Ethereum'),
  [ChainId.ETHEREUM]: new ERC20Token(
    ChainId.ETHEREUM,
    '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    18,
    'ETH',
    'Ethereum',
  ),
}
