import {
  MenuItemsType,
  SwapIcon,
  SwapFillIcon,
  EarnFillIcon,
  EarnIcon,
  MoreIcon,
  DropdownMenuItems,
} from '@grokswap/uikit'
import { ContextApi } from '@grokswap/localization'
import { SUPPORT_FARMS, SUPPORT_ONLY_BSC, SUPPORT_ONLY_OPBSC } from 'config/constants/supportChains'
import { ChainId } from '@grokswap/sdk'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
  items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item
  }
  return {
    ...item,
    disabled: true,
  }
}

const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
  [
    {
      label: t('Trade'),
      icon: SwapIcon,
      fillIcon: SwapFillIcon,
      href: '/swap',
      showItemsOnMobile: false,
      items: [
        {
          label: t('Swap'),
          href: '/swap',
        },
        {
          label: t('Liquidity'),
          href: '/liquidity',
        },
        // {
        //   label: t('Limit'),
        //   href: '/limit-orders',
        //   supportChainIds: SUPPORT_ONLY_BSC,
        //   image: '/images/decorations/3d-coin.png',
        // },
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
    // {
    //   label: t('Earn'),
    //   href: '/farms',
    //   icon: EarnIcon,
    //   fillIcon: EarnFillIcon,
    //   supportChainIds: SUPPORT_FARMS,
    //   image: '/images/decorations/pe2.png',
    //   items: [
    //     {
    //       label: t('Farms'),
    //       href: '/farms',
    //       supportChainIds: SUPPORT_FARMS,
    //     },
    //     {
    //       label: t('Pools'),
    //       href: '/pools',
    //       supportChainIds: SUPPORT_FARMS,
    //     },
    //   ].map((item) => addMenuItemSupported(item, chainId)),
    // },
    // {
    //   label: t('Launchpad'),
    //   href: '/launchpad/create-presale',
    //   icon: EarnIcon,
    //   fillIcon: EarnFillIcon,
    //   items: [
    //     {
    //       label: t('Create Presale'),
    //       status: 'soon',
    //       href: '/launchpad/create-presale',
    //       supportChainIds: [...SUPPORT_FARMS, ChainId.SCROLL],
    //     },
    //     {
    //       label: t('Create token'),
    //       href: '/launchpad/create-token',
    //       supportChainIds: [...SUPPORT_FARMS, ChainId.SCROLL],
    //     },
    //   ].map((item) => addMenuItemSupported(item, chainId)),
    // },
    // {
    //   label: 'More',
    //   icon: MoreIcon,
    //   items: [
    //     {
    //       label: t('Bridge opbnb'),
    //       href: 'https://opbnb-bridge.bnbchain.org',
    //       type: 'EXTERNAL_LINK',
    //     },
    //     {
    //       label: t('OPBNB Scan'),
    //       href: 'https://opbnbscan.com/address/0x47a32A42248Ac1901792FD357dD560005E19D7Bb',
    //       supportChainIds: SUPPORT_ONLY_OPBSC,
    //       type: 'EXTERNAL_LINK',
    //     },
    //     {
    //       label: t('BSC Scan'),
    //       href: 'https://bscscan.com/address/0x47a32A42248Ac1901792FD357dD560005E19D7Bb',
    //       supportChainIds: SUPPORT_ONLY_BSC,
    //       type: 'EXTERNAL_LINK',
    //     },
    //   ].map((item) => addMenuItemSupported(item, chainId)),
    // },
  ].map((item) => addMenuItemSupported(item, chainId))

export default config
