import { useUserTheXLockStatus } from 'hooks/useUserTheXLockStatus'
import { useMemo } from 'react'
import { useCompetitionStatus } from './useCompetitionStatus'
import { usePotteryStatus } from './usePotteryStatus'
import { useVotingStatus } from './useVotingStatus'

export const useMenuItemsStatus = (): Record<string, string> => {
  const competitionStatus = useCompetitionStatus()
  const potteryStatus = usePotteryStatus()
  const votingStatus = useVotingStatus()
  const isUserLocked = useUserTheXLockStatus()

  return useMemo(() => {
    return {
      '/competition': competitionStatus,
      '/launchpad/create-presale': 'soon',
    }
  }, [competitionStatus, potteryStatus, votingStatus, isUserLocked])
}
