import { ChainId, WBNB, ERC20Token } from '@grokswap/sdk'
import { BUSD_BSC, XAI_MAINNET, USDT_BSC } from './common'

export const bscTokens = {
  wbnb: WBNB[ChainId.BSC],
  // bnb here points to the wbnb contract. Wherever the currency BNB is required, conditional checks for the symbol 'BNB' can be used
  bnb: new ERC20Token(
    ChainId.BSC,
    '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    18,
    'BNB',
    'BNB',
    'https://www.binance.com/',
  ),
  xai: XAI_MAINNET,
  gmi: new ERC20Token(
    ChainId.BSC,
    '0x93D8d25E3C9A847a5Da79F79ecaC89461FEcA846',
    18,
    'GMI',
    'Gamifi',
    'https://gamifi.gg/',
  ),
  tlos: new ERC20Token(
    ChainId.BSC,
    '0xb6C53431608E626AC81a9776ac3e999c5556717c',
    18,
    'TLOS',
    'Telos',
    'https://www.telos.net/',
  ),
  beta: new ERC20Token(
    ChainId.BSC,
    '0xBe1a001FE942f96Eea22bA08783140B9Dcc09D28',
    18,
    'BETA',
    'Beta Finance',
    'https://betafinance.org',
  ),
  nft: new ERC20Token(
    ChainId.BSC,
    '0x1fC9004eC7E5722891f5f38baE7678efCB11d34D',
    6,
    'NFT',
    'APENFT',
    'https://apenft.org',
  ),
  stephero: new ERC20Token(
    ChainId.BSC,
    '0xE8176d414560cFE1Bf82Fd73B986823B89E4F545',
    18,
    'HERO',
    'StepHero',
    'https://stephero.io/',
  ),
  pros: new ERC20Token(
    ChainId.BSC,
    '0xEd8c8Aa8299C10f067496BB66f8cC7Fb338A3405',
    18,
    'PROS',
    'Prosper',
    'https://prosper.so/',
  ),
  qbt: new ERC20Token(
    ChainId.BSC,
    '0x17B7163cf1Dbd286E262ddc68b553D899B93f526',
    18,
    'QBT',
    'Qubit Token',
    'https://qbt.fi/',
  ),
  cvp: new ERC20Token(
    ChainId.BSC,
    '0x5Ec3AdBDae549Dce842e24480Eb2434769e22B2E',
    18,
    'CVP',
    'Concentrated Voting Power Token',
    'https://powerpool.finance/',
  ),
  bscdefi: new ERC20Token(
    ChainId.BSC,
    '0x40E46dE174dfB776BB89E04dF1C47d8a66855EB3',
    18,
    'BSCDEFI',
    'ChainId.BSC Defi blue chips token',
    'https://powerpool.finance/',
  ),
  busd: BUSD_BSC,
  dai: new ERC20Token(
    ChainId.BSC,
    '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
    18,
    'DAI',
    'Dai Stablecoin',
    'https://www.makerdao.com/',
  ),
  usdt: USDT_BSC,
  btcb: new ERC20Token(
    ChainId.BSC,
    '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    18,
    'BTCB',
    'Binance BTC',
    'https://bitcoin.org/',
  ),
  ust: new ERC20Token(
    ChainId.BSC,
    '0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
    18,
    'UST',
    'Wrapped UST Token',
    'https://mirror.finance/',
  ),
  eth: new ERC20Token(
    ChainId.BSC,
    '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    18,
    'ETH',
    'Binance-Peg Ethereum Token',
    'https://ethereum.org/en/',
  ),
  usdc: new ERC20Token(
    ChainId.BSC,
    '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    18,
    'USDC',
    'Binance-Peg USD Coin',
    'https://www.centre.io/usdc',
  ),
  kalm: new ERC20Token(
    ChainId.BSC,
    '0x4BA0057f784858a48fe351445C672FF2a3d43515',
    18,
    'KALM',
    'Kalmar Token',
    'https://kalmar.io/',
  ),
  dkt: new ERC20Token(
    ChainId.BSC,
    '0x7Ceb519718A80Dd78a8545AD8e7f401dE4f2faA7',
    18,
    'DKT',
    'Duelist King',
    'https://duelistking.com/',
  ),
  hotcross: new ERC20Token(
    ChainId.BSC,
    '0x4FA7163E153419E0E1064e418dd7A99314Ed27b6',
    18,
    'HOTCROSS',
    'Hotcross Token',
    'https://www.hotcross.com/',
  ),
  belt: new ERC20Token(
    ChainId.BSC,
    '0xE0e514c71282b6f4e823703a39374Cf58dc3eA4f',
    18,
    'BELT',
    'Belt Token',
    'https://beta.belt.fi/',
  ),
}
