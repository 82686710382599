import { useAccount } from 'wagmi'
import { ChainId } from '@grokswap/sdk'
import useSWRImmutable from 'swr/immutable'
import { useTheXVaultContract } from 'hooks/useContract'
import { useActiveChainId } from './useActiveChainId'

export const useUserTheXLockStatus = () => {
  const { address: account } = useAccount()
  const { chainId } = useActiveChainId()
  const theXVaultContract = useTheXVaultContract()

  const { data: userTheXLockStatus = null } = useSWRImmutable(
    account && chainId === ChainId.BSC ? ['userTheXLockStatus', account] : null,
    async () => {
      const { locked, lockEndTime } = await theXVaultContract.userInfo(account)
      const lockEndTimeStr = lockEndTime.toString()
      return locked && (lockEndTimeStr === '0' || new Date() > new Date(parseInt(lockEndTimeStr) * 1000))
    },
  )
  return userTheXLockStatus
}
