import { ChainId } from '@grokswap/sdk'
import memoize from 'lodash/memoize'
import invert from 'lodash/invert'
import { bsc as bsc_, Chain, mainnet as eth_ } from '@wagmi/chains'

export const CHAIN_QUERY_NAME = {
  [ChainId.ETHEREUM]: 'eth',
  [ChainId.GOERLI]: 'goerli',
  [ChainId.BSC]: 'bsc',
  [ChainId.OPBNB]: 'opbnb',
  [ChainId.SCROLL]: 'scroll',
  [ChainId.BSC_TESTNET]: 'bscTestnet',
} satisfies Record<ChainId, string>

const CHAIN_QUERY_NAME_TO_ID = invert(CHAIN_QUERY_NAME)

export const getChainId = memoize((chainName: string) => {
  if (!chainName) return undefined
  return CHAIN_QUERY_NAME_TO_ID[chainName] ? +CHAIN_QUERY_NAME_TO_ID[chainName] : undefined
})

export const opbnb: Chain = {
  id: ChainId.OPBNB,
  name: 'opBNB Chain',
  network: 'opbnb',
  nativeCurrency: bsc_.nativeCurrency,
  rpcUrls: {
    default: {
      http: ['https://opbnb-mainnet-rpc.bnbchain.org'],
    },
    public: {
      http: ['https://opbnb-mainnet-rpc.bnbchain.org'],
    },
  },
  blockExplorers: {
    default: {
      name: 'opBNBScan',
      url: 'https://opbnbscan.com',
    },
  },
  contracts: {
    multicall3: {
      address: '0x21e4691786aE7a1FB2975539cfFE448f1749FD11',
      blockCreated: 512881,
    },
  },
  testnet: false,
}


export const scroll: Chain = {
  id: ChainId.SCROLL,
  name: 'Scroll Chain',
  network: 'scroll',
  nativeCurrency: eth_.nativeCurrency,
  rpcUrls: {
    default: {
      http: ['https://rpc.scroll.io'],
    },
    public: {
      http: ['https://rpc.scroll.io'],
    },
  },
  blockExplorers: {
    default: {
      name: 'ScrollScan',
      url: 'https://blockscout.scroll.io',
    },
  },
  contracts: {
    multicall3: {
      address: '0x056B35f8D17B5975Fd8fd8A26A5BCeeEbDd78611',
      blockCreated: 20883,
    },
  },
  testnet: false,
}
