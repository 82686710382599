import { BigNumber } from '@ethersproject/bignumber'
import { Pool } from '@grokswap/uikit'
import { SerializedWrappedToken } from '@grokswap/token-lists'
import Trans from 'components/Trans'
import { VaultKey } from 'state/types'
import { opbnbTokens } from '@grokswap/tokens'
import { PoolCategory } from './types'

export const MAX_LOCK_DURATION = 31536000
export const UNLOCK_FREE_DURATION = 604800
export const ONE_WEEK_DEFAULT = 604800
export const BOOST_WEIGHT = BigNumber.from('20000000000000')
export const DURATION_FACTOR = BigNumber.from('31536000')

export const vaultPoolConfig = {
  [VaultKey.TheXVaultV1]: {
    name: <Trans>Auto XAI</Trans>,
    description: <Trans>Automatic restaking</Trans>,
    autoCompoundFrequency: 5000,
    gasLimit: 380000,
    tokenImage: {
      primarySrc: `/images/tokens/${opbnbTokens.xai.address}.png`,
      secondarySrc: '/images/tokens/autorenew.svg',
    },
  },
  [VaultKey.TheXVault]: {
    name: <Trans>Stake XAI</Trans>,
    description: <Trans>Stake, Earn – And more!</Trans>,
    autoCompoundFrequency: 5000,
    gasLimit: 600000,
    tokenImage: {
      primarySrc: `/images/tokens/${opbnbTokens.xai.address}.png`,
      secondarySrc: '/images/tokens/autorenew.svg',
    },
  },
  [VaultKey.TheXFlexibleSideVault]: {
    name: <Trans>Flexible X</Trans>,
    description: <Trans>Flexible staking on the side.</Trans>,
    autoCompoundFrequency: 5000,
    gasLimit: 500000,
    tokenImage: {
      primarySrc: `/images/tokens/${opbnbTokens.xai.address}.png`,
      secondarySrc: '/images/tokens/autorenew.svg',
    },
  },
  [VaultKey.IfoPool]: {
    name: 'IFO X',
    description: <Trans>Stake XAI to participate in IFOs</Trans>,
    autoCompoundFrequency: 1,
    gasLimit: 500000,
    tokenImage: {
      primarySrc: `/images/tokens/${opbnbTokens.xai.address}.png`,
      secondarySrc: `/images/tokens/ifo-pool-icon.svg`,
    },
  },
} as const

const _livePools: Pool.SerializedPoolConfig<SerializedWrappedToken>[] = [
  {
    sousId: 0,
    stakingToken: opbnbTokens.xai,
    earningToken: opbnbTokens.xai,
    contractAddress: {
      97: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
      56: '0x19B4a19F3407E1ADe02B0a1F6eFF60e5c43EEb7A',
      204: '0xbb79bb5C045885A01CcC2debBb70171a44B09120',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '1',
    isFinished: false,
  },
].map((p) => ({
  ...p,
  stakingToken: p.stakingToken.serialize,
  earningToken: p.earningToken.serialize,
}))

export const livePools = (chainId: number) => _livePools.filter((v) => v.earningToken?.chainId === chainId);


// known finished pools
const finishedPools = []

export default (chainId: number) =>
  [...livePools(chainId), ...finishedPools] as Pool.SerializedPoolConfig<SerializedWrappedToken>[]