import { BigNumber } from '@ethersproject/bignumber'
import { Campaign, TranslatableText } from 'config/constants/types'
import { campaignMap } from 'config/constants/campaigns'
import { TranslateFunction } from '@grokswap/localization'
import { Achievement } from 'state/types'

interface IfoMapResponse {
  thresholdToClaim: string
  campaignId: string
  numberPoints: BigNumber
}

export const getAchievementTitle = (campaign: Campaign, t: TranslateFunction): TranslatableText => {
  const title = campaign.title as string

  switch (campaign.type) {
    case 'ifo':
      return t('IFO Shopper: %title%', { title })
    default:
      return campaign.title
  }
}

export const getAchievementDescription = (campaign: Campaign, t: TranslateFunction): TranslatableText => {
  const title = campaign.title as string

  switch (campaign.type) {
    case 'ifo':
      return t('Participated in the %title% IFO by committing above the minimum required amount', { title })
    default:
      return campaign.description
  }
}

/**
 * Checks if a wallet is eligible to claim points from valid IFO's
 */
export const getClaimableIfoData = async (account: string, t: TranslateFunction): Promise<Achievement[]> => {
  return []
}
