import { ContextApi } from "@grokswap/localization";
import { FooterLinkType } from "../../../components/Footer/types";

export const footerLinks: (t: ContextApi["t"]) => FooterLinkType[] = (t) => [
  {
    label: t("About"),
    items: [
      {
        label: t("Contact"),
        href: "https://docs.grokswap.finance/contact-us",
      },
      {
        label: t("Community"),
        href: "https://docs.grokswap.finance/contact-us/telegram",
      },
    ],
  },
  {
    label: t("Help"),
    items: [
      {
        label: t("Customer Support"),
        href: "https://docs.grokswap.finance/contact-us/customer-support",
      },
      {
        label: t("Guides"),
        href: "https://docs.grokswap.finance/get-started",
      },
    ],
  },
  {
    label: t("Developers"),
    items: [
      {
        label: "Github",
        href: "https://github.com/grokswapfinance",
      },
      {
        label: t("Documentation"),
        href: "https://docs.grokswap.finance",
      },
    ],
  },
];
