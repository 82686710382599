import { ChainId, ERC20Token, WBNB } from '@grokswap/sdk'
import { XAI, USDT, WBTC, ETH } from '@grokswap/tokens'

export const opbnbTokens = {
  wbnb: WBNB[ChainId.OPBNB],
  usdt: USDT[ChainId.OPBNB],
  xai: XAI[ChainId.OPBNB],
  wbtc: WBTC[ChainId.OPBNB],
  eth: ETH[ChainId.OPBNB],
}
