import { Language } from "../LangSelector/types";
import { FooterLinkType } from "./types";
import { TwitterIcon, TelegramIcon, RedditIcon, InstagramIcon, GithubIcon, DiscordIcon, YoutubeIcon } from "../Svg";

export const footerLinks: FooterLinkType[] = [
  {
    label: "About",
    items: [
      {
        label: "Contact",
        href: "https://docs.grokswap.finance/contact-us",
      },
      {
        label: "Blog",
        href: "https://blog.grokswap.finance/",
      },
    ],
  },
  {
    label: "Help",
    items: [
      {
        label: "Customer",
        href: "Support https://docs.grokswap.finance/contact-us/customer-support",
      },
      {
        label: "Troubleshooting",
        href: "https://docs.grokswap.finance/help/troubleshooting",
      },
    ],
  },
  {
    label: "Developers",
    items: [
      {
        label: "Github",
        href: "https://github.com/grokswapfinance",
      },
      {
        label: "Documentation",
        href: "https://docs.grokswap.finance",
      },
    ],
  },
];

export const socials: any = [
  {
    label: "Twitter",
    icon: TwitterIcon,
    href: "https://twitter.com/grokswap_en",
  },
  {
    label: "Telegram",
    icon: TelegramIcon,
    href: "https://t.me/grokswap_en",
  },
  {
    label: "Reddit",
    icon: RedditIcon,
    href: "https://reddit.com/r/grokswap",
  },
  {
    label: "Github",
    icon: GithubIcon,
    href: "https://github.com/grokswapfinance/",
  },
];

export const langs: Language[] = [...Array(20)].map((_, i) => ({
  code: `en${i}`,
  language: `English${i}`,
  locale: `Locale${i}`,
}));
