import BigNumber from 'bignumber.js'
import { multicallv2, multicallv3 } from 'utils/multicall'
import theXAbi from 'config/abi/cake.json'
import theXVaultAbi from 'config/abi/theXVaultV2.json'
import { getTheXVaultAddress, getTheXFlexibleSideVaultAddress } from 'utils/addressHelpers'
import { BIG_ZERO } from '@grokswap/utils/bigNumber'
import { ChainId } from '@grokswap/sdk'
import { XAI } from '@grokswap/tokens'

export const fetchPublicVaultData = async (chainId?: number) => {
  try {
    const theXVaultV2 = getTheXVaultAddress(chainId)

    const calls = ['getPricePerFullShare', 'totalShares', 'totalLockedAmount'].map((method) => ({
      abi: theXVaultAbi,
      address: theXVaultV2,
      name: method,
    }))

    const theXBalanceOfCall = {
      abi: theXAbi,
      address: XAI[chainId as ChainId].address,
      name: 'balanceOf',
      params: [theXVaultV2],
    }

    const [[sharePrice], [shares], totalLockedAmount, [totalTheXInVault]] = await multicallv3({
      calls: [...calls, theXBalanceOfCall],
      chainId,
      allowFailure: true,
    })

    const totalSharesAsBigNumber = shares ? new BigNumber(shares.toString()) : BIG_ZERO
    const totalLockedAmountAsBigNumber = totalLockedAmount ? new BigNumber(totalLockedAmount[0].toString()) : BIG_ZERO
    const sharePriceAsBigNumber = sharePrice ? new BigNumber(sharePrice.toString()) : BIG_ZERO

    return {
      totalShares: totalSharesAsBigNumber.toJSON(),
      totalLockedAmount: totalLockedAmountAsBigNumber.toJSON(),
      pricePerFullShare: sharePriceAsBigNumber.toJSON(),
      totalTheXInVault: new BigNumber(totalTheXInVault.toString()).toJSON(),
    }
  } catch (error) {
    return {
      totalShares: null,
      totalLockedAmount: null,
      pricePerFullShare: null,
      totalTheXInVault: null,
    }
  }
}

export const fetchPublicFlexibleSideVaultData = async (chainId?: number) => {
  try {
    const theXFlexibleSideVaultV2 = getTheXFlexibleSideVaultAddress(chainId);

    const calls = ['getPricePerFullShare', 'totalShares'].map((method) => ({
      abi: theXVaultAbi,
      address: theXFlexibleSideVaultV2,
      name: method,
    }))

    const theXBalanceOfCall = {
      abi: theXAbi,
      address: XAI[chainId as ChainId].address,
      name: 'balanceOf',
      params: [theXFlexibleSideVaultV2],
    }

    const [[sharePrice], [shares], [totalTheXInVault]] = await multicallv3({
      calls: [...calls, theXBalanceOfCall],
      chainId,
      allowFailure: true,
    })

    const totalSharesAsBigNumber = shares ? new BigNumber(shares.toString()) : BIG_ZERO
    const sharePriceAsBigNumber = sharePrice ? new BigNumber(sharePrice.toString()) : BIG_ZERO

    return {
      totalShares: totalSharesAsBigNumber.toJSON(),
      pricePerFullShare: sharePriceAsBigNumber.toJSON(),
      totalTheXInVault: new BigNumber(totalTheXInVault.toString()).toJSON(),
    }
  } catch (error) {
    return {
      totalShares: null,
      pricePerFullShare: null,
      totalTheXInVault: null,
    }
  }
}

export const fetchVaultFees = async (chainId?: number) => {
  try {
    const theXVaultV2 = getTheXVaultAddress(chainId)

    const calls = ['performanceFee', 'withdrawFee', 'withdrawFeePeriod'].map((method) => ({
      address: theXVaultV2,
      name: method,
    }))

    const [[performanceFee], [withdrawalFee], [withdrawalFeePeriod]] = await multicallv2({
      abi: theXVaultAbi,
      calls,
      chainId,
    })

    return {
      performanceFee: performanceFee.toNumber(),
      withdrawalFee: withdrawalFee.toNumber(),
      withdrawalFeePeriod: withdrawalFeePeriod.toNumber(),
    }
  } catch (error) {
    return {
      performanceFee: null,
      withdrawalFee: null,
      withdrawalFeePeriod: null,
    }
  }
}

export default fetchPublicVaultData
