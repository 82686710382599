import { FC } from "react";
import Lottie, { LottieProps, Options } from "react-lottie";
import * as animationData from "./data/space-loading.json";
import * as animationCircle from "./data/circle.json";
import * as animationEarth from "./data/earth.json";
import * as animationSpace from "./data/spaceship.json";

interface LottieIconProps extends Omit<LottieProps, "options"> {
  type?: "loading" | "circle" | "earth" | "spaceship";
  options?: Options;
}

const data = {
  loading: animationData,
  earth: animationEarth,
  circle: animationCircle,
  spaceship: animationSpace,
};

export const LottieIcon: FC<LottieIconProps> = ({ options, type, ...props }: LottieIconProps) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
    ...options,
    animationData: options?.animationData || (data as any)[type || 'loading'] || data.loading,
  };

  return <Lottie options={defaultOptions} height={100} width={100} {...props} />;
};
