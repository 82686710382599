import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg width={96} viewBox="0 0 371 371" {...props}>
      <path
        d="M371 185.5C371 287.949 287.949 371 185.5 371C83.0512 371 0 287.949 0 185.5C0 83.0512 83.0512 0 185.5 0C287.949 0 371 83.0512 371 185.5Z"
        fill="black"
      />
      <path
        d="M90 94.85L137.02 12L182.61 94.85H176.66L165.11 74.02H125.35L113.69 94.85H107.86L142.62 33.19L136.67 22.95L95.83 94.85H90ZM145.71 38.78L128.57 69.02H162.26L145.71 38.78Z"
        fill="white"
      />
      <path
        d="M91 94.6056H147.445L199.618 164.674L264.56 93L279.991 93.2676L207.311 174.753L281.847 274.838H225.425L176.181 209.542L116.024 276.443H100.84L168.712 199.997L91 94.6056ZM142.122 104.953H112.312L231.575 264.223H260.848L142.122 104.953Z"
        fill="white"
      />
    </Svg>
  );
};

export default Icon;
